import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useQuery } from '@tanstack/react-query';
import { useAlert } from '../../../AlertProvider';
import Divider from '../../../components/Divider';
import Tabs from '../../../components/Tabs';
import { fetchMyMeetings } from '../../../services/meetingService';
import MeetingType from '../../../types/MeetingDateType';
import MeetingsConfirmationTable from './meetingsConfirmationTable';
import MeetingsHistoryTable from './meetingsHistoryTable';
import MeetingsTable from './meetingsTable';
import styles from './style.module.scss';

function Meetings() {
    const alert = useAlert();

    const { data: meetings } = useQuery({
        queryKey: ['meeting'],
        queryFn: async () => {
            const { data } = await fetchMyMeetings();
            return data;
        },
    });

    const headerToolbar = {
        start: 'prev,today', // will normally be on the left. if RTL, will be on the right
        center: 'title',
        end: 'next', // will normally be on the right. if RTL, will be on the left
    };

    const onEventClick = (info: any) => {};

    const onDateSelected = (info: any) => {
        const selectedTime = new Date(info.start);
        if (selectedTime < new Date()) {
            alert.show({
                children:
                    "Le créneau horaire selectionné n'est plus disponible. Veuillez en selectionner un autre.",
                type: 'error',
            });
            return;
        }
    };

    const events: MeetingType[] = meetings;

    const eventsToValidate = events?.filter((event: MeetingType) => {
        return event.confirmationDate === null;
    });
    const upcomingEvents = events?.filter((event: MeetingType) => {
        return new Date(event.end) >= new Date();
    });
    const pastEvents = events?.filter((event: MeetingType) => {
        return new Date(event.end) < new Date();
    });

    const tabs = [
        {
            name: 'meeting-to-confirm',
            content: <MeetingsConfirmationTable events={eventsToValidate} />,
            title: 'En attente',
        },
        {
            name: 'meeting',
            content: <MeetingsTable events={upcomingEvents} />,
            title: 'Confirmés',
        },
        {
            name: 'past-meeting',
            content: <MeetingsHistoryTable events={pastEvents} />,
            title: 'Historique',
        },
    ];

    return (
        //mettre une liste des rdv avec Nom Prénom du client, numero de dossier et lien vers le dossier
        <div className={styles.container}>
            <h1>Rendez-vous</h1>
            <Divider />
            <div className={styles.meetings}>
                <div className={styles.calendar}>
                    <FullCalendar
                        plugins={[
                            timeGridPlugin,
                            dayGridPlugin,
                            listPlugin,
                            interactionPlugin,
                        ]}
                        initialDate={new Date()}
                        initialView={'listWeek'}
                        events={meetings}
                        eventClick={onEventClick}
                        selectable={true}
                        weekends={false}
                        select={onDateSelected}
                        nowIndicator={true}
                        locale={'fr'}
                        headerToolbar={headerToolbar}
                        selectConstraint={'businessHours'}
                        allDaySlot={false}
                    />
                </div>
                <div className={styles.metingTab}>
                    <Tabs
                        activeName={
                            eventsToValidate?.length > 0
                                ? 'meeting-to-confirm'
                                : 'meeting'
                        }
                        tabs={tabs}
                    />
                </div>
            </div>
        </div>
    );
}

export default Meetings;
