import Button from '../../Button';
import FaIcon from '../../Icons/FaIcon';
import WidgetCard from '../WidgetCard';
import styles from './style.module.scss';
function NewClientsCasesWidget() {
    return (
        <WidgetCard title="Nouvelles demandes">
            <ul className={styles.widgetMsgs}>
                <li>
                    <Button
                        variant="secondaryLight"
                        className={styles.widgetMsg}
                    >
                        <FaIcon>fa-folder</FaIcon>
                        <span>Dossier EBXXX01 Elom BLIVI</span>
                    </Button>
                </li>
                <li>
                    <Button
                        variant="secondaryLight"
                        className={styles.widgetMsg}
                    >
                        <FaIcon>fa-folder</FaIcon>
                        <span>Dossier MBXXYY28 Magnolia BLIVI</span>
                    </Button>
                </li>
                <li>
                    <Button
                        variant="secondaryLight"
                        className={styles.widgetMsg}
                    >
                        <FaIcon>fa-folder</FaIcon>
                        <span>Dossier MBXXYY24 Fafa BLIVI</span>
                    </Button>
                </li>
            </ul>
        </WidgetCard>
    );
}

export default NewClientsCasesWidget;
