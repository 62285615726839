import { useQuery } from '@tanstack/react-query';
import { clientCasesIdPage } from '../../../constants/pagesRoutesContants';
import { fetchCaseBookmark } from '../../../services/caseService';
import CaseType from '../../../types/CaseType';
import FaIcon from '../../Icons/FaIcon';
import WidgetCard from '../WidgetCard';
import styles from './style.module.scss';
function ClientsCasesBookmarkWidget() {
    const { data, isPending } = useQuery({
        queryKey: ['getClientCaseBookmark'],
        queryFn: async () => {
            const { data } = await fetchCaseBookmark();
            return data;
        },
    });

    return (
        <>
            <WidgetCard title="Dossiers favoris" isPending={isPending}>
                <div className={styles.containerFav}>
                    {data?.map((item: CaseType) => (
                        <a
                            href={clientCasesIdPage.path + item.id}
                            key={item.id}
                            className={styles.fav}
                        >
                            <FaIcon>fa-folder</FaIcon>
                            <small>{item.caseName || item.caseRef}</small>
                            <span className={styles.favName}>{item.user}</span>
                        </a>
                    ))}
                </div>
            </WidgetCard>
        </>
    );
}

export default ClientsCasesBookmarkWidget;
