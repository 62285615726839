import { useMutation, useQuery } from '@tanstack/react-query';
import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useAlert } from '../AlertProvider';
import { alertType } from '../constants/alertTypeConstant';
import { successStatus } from '../constants/requestStatusContants';
import { storageItems } from '../constants/storageItemsContants';
import Role from '../enums/RoleEnum';
import AuthContextInterface from '../interfaces/AuthContextInterface';
import LoginInterface from '../interfaces/LoginInterface';
import { login, logout } from '../services/accountService';
import { fetchUser } from '../services/userService';
import UserType from '../types/UserType';

// Create a context for authentication

interface Props {
    children: ReactNode;
}

const AuthContext = createContext<AuthContextInterface>(
    {} as AuthContextInterface
);

export const AuthProvider = (props: Props) => {
    const { children } = props;
    const alert = useAlert();
    const isAuthenticated: boolean = !!localStorage.getItem(storageItems.user);
    const [isAuth, setIsAuth] = useState<boolean>(isAuthenticated);
    const [user, setUser] = useState<UserType | null>(null);

    const userLogout = () => {
        if (isAuth) {
            logout();
            setIsAuth(false);
            setUser(null);
        }
    };

    useEffect(() => {
        if (!isAuthenticated) {
            userLogout();
        }
    });

    useQuery({
        queryKey: ['me', isAuth, user],
        queryFn: async () => {
            if (isAuthenticated && !user) {
                const { status, data } = await fetchUser();
                if (successStatus.includes(status)) {
                    setUser(data);
                }
                return data;
            }
            return null;
        },
    });

    const role: Role = user?.role || Role.User;

    const { mutate } = useMutation({
        mutationFn: async (data: LoginInterface) => {
            const { status } = await login(data);
            if (
                successStatus.includes(status) &&
                localStorage.getItem(storageItems.user)
            ) {
                setIsAuth(true);
            } else {
                alert.show({
                    children:
                        'Erreur! Une erreur est survenue. Veuillez réessayer plus tard.',
                    type: alertType[status],
                });
            }
        },
    });

    const userLogin = (loginInfo: LoginInterface) => {
        mutate(loginInfo);
    };

    const newValue = {
        isAuth,
        user,
        role,
        userLogin,
        userLogout,
    };

    return (
        <AuthContext.Provider value={newValue}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
