import Button from '../../Button';
import FaIcon from '../../Icons/FaIcon';
import WidgetCard from '../WidgetCard';
import styles from './style.module.scss';
function NewMessagesWidget() {
    return (
        <WidgetCard title="Nouveaux Messages">
            <ul className={styles.widgetMsgs}>
                <li>
                    <Button
                        variant="secondaryLight"
                        className={styles.widgetMsg}
                    >
                        <FaIcon>fa-envelope</FaIcon>
                        <span>5 messages de Elom BLIVI</span>
                    </Button>
                </li>
                <li>
                    <Button
                        variant="secondaryLight"
                        className={styles.widgetMsg}
                    >
                        <FaIcon>fa-envelope</FaIcon>
                        <span>2 messages de Magnolia BLIVI</span>
                    </Button>
                </li>
                <li>
                    <Button
                        variant="secondaryLight"
                        className={styles.widgetMsg}
                    >
                        <FaIcon>fa-envelope</FaIcon>
                        <span>1000 messages de Fafa BLIVI</span>
                    </Button>
                </li>
            </ul>
        </WidgetCard>
    );
}

export default NewMessagesWidget;
