import React from 'react';
import { classNames } from '../../helpers/classNames';
import Button from '../Button';
import Card from '../Card';
import FaIcon from '../Icons/FaIcon';
import styles from './style.module.scss';

interface Props {
    title?: string;
    children: React.ReactNode;
    onOpen?: (param?: any) => any;
    onClose?: (param?: any) => any;
    onClick?: (param?: any) => any;
    setOpened: (param?: any) => any;
    opened: boolean;
    actionLabel?: string;
}

function ModalAction(props: Props) {
    const {
        title,
        children,
        onOpen,
        onClose,
        actionLabel = 'Valider',
        onClick,
        opened,
        setOpened,
    } = props;

    if (onOpen) {
        onOpen();
    }

    const closeModal = (event: React.MouseEvent): void => {
        setOpened(false);
        if (onClose) {
            onClose();
        }
    };

    const clickActionModal = (event: React.MouseEvent): void => {
        if (onClick) {
            onClick(event);
        }
    };

    if (opened)
        return (
            <div className={classNames([styles.modal])}>
                <Card className={styles.card}>
                    <Button
                        className={styles.btnClose}
                        onClick={closeModal}
                        variant="secondaryLight"
                    >
                        <FaIcon>fa-remove</FaIcon>
                    </Button>
                    {title && <span className={styles.title}>{title}</span>}
                    <div className={styles.body}>{children}</div>
                    <div className={styles.footer}>
                        <Button
                            variant="primaryLightBordered"
                            onClick={closeModal}
                        >
                            Annuler
                        </Button>
                        <Button onClick={clickActionModal}>
                            {actionLabel}
                        </Button>
                    </div>
                </Card>
            </div>
        );
    return <></>;
}

export default ModalAction;
