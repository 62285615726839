import { authEndpoint as endpoint } from '../constants/endpointsContants';
import { storageItems } from '../constants/storageItemsContants';
import ChangePasswordInterface from '../interfaces/ChangePasswordInterface';
import LoginInterface from '../interfaces/LoginInterface';
import RegisterInterface from '../interfaces/RegisterInterface';
import ResetPasswordInterface from '../interfaces/ResetPasswordInterface';
import request from './genericRequestService';

async function login(data: LoginInterface) {
    const result = await request.post({
        endpoint: endpoint + '/login',
        data,
    });
    localStorage.setItem(
        storageItems.user,
        JSON.stringify({ ...result.data, email: data.email })
    );
    return result;
}

function logout() {
    // request.post({
    //     endpoint: endpoint + '/logout',
    // });
    localStorage.removeItem(storageItems.user);
}

async function register(data: RegisterInterface) {
    const resultRegister = await request.post({
        endpoint: endpoint + '/registerUser',
        data,
    });

    return resultRegister;
}

async function changePassword(data: ChangePasswordInterface) {
    return await request.post({
        endpoint: endpoint + '/password/change',
        data,
    });
}

async function resetPassword(data: ResetPasswordInterface) {
    return await request.post({ endpoint: endpoint + '/password/reset', data });
}

async function forgotPassword(email: string) {
    return await request.post({
        endpoint: endpoint + '/password/forgot',
        data: {
            email: email,
        },
    });
}

async function resendConfirmationEmail(email: string) {
    return await request.post({
        endpoint: endpoint + '/resendConfirmationEmail',
        data: {
            email: email,
        },
    });
}

export {
    changePassword,
    forgotPassword,
    login,
    logout,
    register,
    resendConfirmationEmail,
    resetPassword,
};
