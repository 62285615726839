import Stopwatch from '../../Stopwatch';
import WidgetCard from '../WidgetCard';
function StopwatchWidget() {
    return (
        <WidgetCard>
            <Stopwatch isMini={true} />
        </WidgetCard>
    );
}

export default StopwatchWidget;
