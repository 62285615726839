import { useAuth } from '../../../AuthProvider';
import {
    dashboardPage,
    homePage,
    lawfirmsPage,
    lawyersPage,
    loginPage,
    pricing,
} from '../../../constants/pagesRoutesContants';
import Role from '../../../enums/RoleEnum';
import { classNames } from '../../../helpers/classNames';
import Button from '../../Button';
import ButtonLink from '../../ButtonLink';
import FaIcon from '../../Icons/FaIcon';
import styles from './style.module.scss';

function Header() {
    const { userLogout, isAuth, role } = useAuth();

    return (
        <div className={classNames([styles.navbar])}>
            <ButtonLink variant={'tertiary'} link="/">
                <span className={classNames([styles.title])}>LiberAll</span>
            </ButtonLink>
            <div className={styles.buttons}>
                <ButtonLink link={homePage.path} variant="tertiary">
                    {homePage.name}
                </ButtonLink>
                <ButtonLink link={lawyersPage.path} variant="tertiary">
                    {lawyersPage.name}
                </ButtonLink>
                <ButtonLink link={lawfirmsPage.path} variant="tertiary">
                    {lawfirmsPage.name}
                </ButtonLink>
                {isAuth && role === Role.Administrator && (
                    <ButtonLink link={pricing.path} variant="tertiary">
                        {pricing.name}
                    </ButtonLink>
                )}
                {/* <ButtonLink link={juristsPage.path}>Juristes</ButtonLink> */}
                {/* <ButtonLink link={informationPage.path}>Informations</ButtonLink> */}
                {isAuth ? (
                    <>
                        <ButtonLink link={dashboardPage.path}>
                            Mon Compte
                        </ButtonLink>
                        <Button variant="primaryLight" onClick={userLogout}>
                            <FaIcon>fa-power-off</FaIcon>
                        </Button>
                    </>
                ) : (
                    <ButtonLink link={loginPage.path}>
                        {loginPage.name}
                    </ButtonLink>
                )}
            </div>
        </div>
    );
}

export default Header;
