import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from '../Input';
import styles from './style.module.scss';

interface Props {
    placeholder?: string;
    onChange?: (event: any) => any;
}

function SearchInput(props: Props) {
    const { onChange, placeholder } = props;
    return (
        <div className={styles.search}>
            <FontAwesomeIcon icon={faSearch} fontSize={'20px'} />
            <Input
                inputClassNames={styles.input}
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    );
}

export default SearchInput;
