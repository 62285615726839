import { ReactNode, useEffect, useState } from 'react';
import { classNames } from '../../helpers/classNames';
import styles from './style.module.scss';

interface Props {
    activeName: string;
    tabs: {
        name: string;
        title: string;
        content: ReactNode;
    }[];
}

function Tabs(props: Props) {
    const { tabs, activeName } = props;
    const [activeTabName, setActiveTabName] = useState<string>('');

    useEffect(() => {
        setActiveTabName(activeName);
    }, [setActiveTabName, activeName]);

    const handleClickTab = (event: any) => {
        const { name } = event.target;
        setActiveTabName(name);
    };
    const contentTab = tabs.find((tab) => {
        return tab.name === activeTabName;
    });

    return (
        <div className={classNames([styles.tabsContainer])}>
            <div className={classNames([styles.tab])}>
                {tabs.map((tab) => (
                    <button
                        onClick={handleClickTab}
                        name={tab.name}
                        className={
                            activeTabName === tab.name
                                ? styles.active
                                : styles.btn
                        }
                    >
                        {tab.title}
                    </button>
                ))}
            </div>
            <div className={classNames([styles.content])}>
                {contentTab?.content}
            </div>
        </div>
    );
}

export default Tabs;
