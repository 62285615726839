import React from 'react';
import { classNames } from '../../helpers/classNames';
import styles from './style.module.scss';

interface Props {
    className?: any;
    children: React.ReactNode;
    type?: 'shadow' | 'border';
    cardSize?: {
        width: number;
        height: number;
    };
}

function Card(props: Props) {
    const { children, className, type = 'shadow' } = props;
    return (
        <div className={classNames([styles.card, styles[type], className])}>
            {children}
        </div>
    );
}

export default Card;
