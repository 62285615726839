import axios from 'axios';
import { createEndpoint } from '../constants/endpointsContants';
import { requestStatus } from '../constants/requestStatusContants';
import { storageItems } from '../constants/storageItemsContants';
import { logout } from './accountService';
const headersConfig = { 'Content-Type': 'application/json' };
interface Request {
    endpoint: string;
    id?: string | number;
    data?: any;
    params?: any;
    token?: string;
    config?: any;
}

async function getRequest(requestData: Request) {
    const { endpoint, id = '', params } = requestData;
    const url = createEndpoint([endpoint, id.toString()]);
    const cred = localStorage.getItem(storageItems.user);
    let config: { [key: string]: any } = {};

    if (cred) {
        const token = JSON.parse(cred).accessToken;
        config = {
            headers: { ...headersConfig, Authorization: `Bearer ${token}` },
        };
    }
    return await axios
        .get(url, { ...config, params })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            disconnectAfterTokenExpired(error.status);
            return error;
        });
}

async function postRequest(requestData: Request) {
    const { endpoint, id = '', data } = requestData;
    const url = createEndpoint([endpoint, id.toString()]);
    const cred = localStorage.getItem(storageItems.user);
    let config: { [key: string]: any } = {};
    if (cred) {
        const token = JSON.parse(cred).accessToken;
        config = {
            headers: { ...headersConfig, Authorization: `Bearer ${token}` },
        };
    }
    const res = await axios
        .post(url, data, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            disconnectAfterTokenExpired(error.status);
            return error;
        });
    return res;
}

async function putRequest(requestData: Request) {
    const { endpoint, id = '', data } = requestData;
    const url = createEndpoint([endpoint, id.toString()]);
    const cred = localStorage.getItem(storageItems.user);
    let config: { [key: string]: any } = {};
    if (cred) {
        const token = JSON.parse(cred).accessToken;
        config = {
            headers: { ...headersConfig, Authorization: `Bearer ${token}` },
        };
    }
    const res = await axios
        .put(url, data, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            disconnectAfterTokenExpired(error.status);
            return error;
        });
    return res;
}

async function deleteRequest(requestData: Request) {
    const { endpoint, id = '' } = requestData;
    const url = createEndpoint([endpoint, id.toString()]);
    const cred = localStorage.getItem(storageItems.user);
    let config: { [key: string]: any } = {};
    if (cred) {
        const token = JSON.parse(cred).accessToken;
        config = {
            headers: {
                ...headersConfig,
                Authorization: `Bearer ${token}`,
            },
        };
    }
    const res = await axios
        .delete(url, config)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            disconnectAfterTokenExpired(error.status);
            return error;
        });
    return res;
}

const request = {
    get: getRequest,
    post: postRequest,
    put: putRequest,
    delete: deleteRequest,
};

function disconnectAfterTokenExpired(status: number | undefined) {
    if (status === requestStatus.Unauthorized) {
        logout();
    }
}

export default request;
