// import styles from "./style.module.scss";
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useRef } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Link } from 'react-router-dom';
import location from '../../assets/location.svg';
import { initialCoordonates } from '../../constants/mapContants';
import MapDataInterface from '../../interfaces/MapDataInterface';
import AddressType from '../../types/AddressType';
import LocationMarker from './LocationMarker';

interface Props {
    userLocation: AddressType;
    data: MapDataInterface[];
}

function Map(props: Props) {
    const { data, userLocation } = props;
    const mapRef = useRef(null);

    const locationIcon = new Icon({
        iconUrl: location,
        iconSize: [32, 32],
    });

    return (
        <MapContainer
            center={[initialCoordonates.latitude, initialCoordonates.longitude]}
            zoom={initialCoordonates.zoom}
            ref={mapRef}
            style={{ height: '400px', width: '100%' }}
            scrollWheelZoom={false}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {data.map(
                (item) =>
                    (item.latitude || item.longitude) && (
                        <Marker
                            key={item.id}
                            position={[item.latitude, item.longitude]}
                            icon={item.icon ? item.icon : locationIcon}
                        >
                            <Popup>
                                {item.popup ? (
                                    item.popup
                                ) : (
                                    <Link to={item.link || ''}>
                                        {item.label}
                                    </Link>
                                )}
                            </Popup>
                        </Marker>
                    )
            )}
            <LocationMarker userLocation={userLocation} />
        </MapContainer>
    );
}

export default Map;
