import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '../../../../components/Autocomplete';
import {
    messagesIdPage,
    messagesPage,
} from '../../../../constants/pagesRoutesContants';
import NavDataInterface from '../../../../interfaces/NavDataInterface';
import OptionInterface from '../../../../interfaces/OptionInterface';
import { fetchContact } from '../../../../services/contactService';
import { fetchConversationByRecipientId } from '../../../../services/messageService';
import UserType from '../../../../types/UserType';

interface Props {
    setToolbar: (toolbar: NavDataInterface[]) => void;
    setRecipient: (recipient: OptionInterface) => void;
    setTypeAction: (
        param: 'newConversationForm' | 'newConversation' | 'conversation' | null
    ) => void;
}

function ContactForm(props: Props) {
    const { setRecipient, setToolbar, setTypeAction } = props;
    const navigate = useNavigate();

    const { mutate } = useMutation({
        mutationFn: async (id: string) => {
            const result = await fetchConversationByRecipientId(id);
            if (result.data?.id != null) {
                navigate(messagesIdPage.path + result.data.id);
            } else {
                setTypeAction('newConversation');
            }
        },
    });

    const handleSelect = (value: OptionInterface | undefined) => {
        if (value?.id) {
            mutate(value?.id);
            setRecipient(value);
        }
    };

    const { isPending: isPendingContact, data: contacts } = useQuery({
        queryKey: ['contacts'],
        queryFn: async () => {
            try {
                const { data } = await fetchContact();
                return data || [];
            } catch (error) {}
            return [];
        },
    });

    const optionsContacts: OptionInterface[] = contacts?.map(
        (contact: UserType) => {
            return {
                label: contact.firstName + ' ' + contact.lastName,
                name: contact.firstName + ' ' + contact.lastName,
                id: contact.id,
                value: contact.id,
            };
        }
    );

    useEffect(() => {
        const handleCloseConversation = () => {
            navigate(messagesPage.path);
        };
        const toolbar: NavDataInterface[] = [
            {
                id: 'toolbarBtnClose',
                icon: 'fa-remove',
                label: 'Fermer',
                position: 'end',
                onClick: handleCloseConversation,
            },
        ];

        setToolbar(toolbar);
    }, [setToolbar, navigate]);

    return (
        <div>
            <Autocomplete
                label="Destinataire"
                options={optionsContacts}
                onOptionClick={handleSelect}
                isDataLoading={isPendingContact}
            />
        </div>
    );
}

export default ContactForm;
