import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import Loader from '../components/Loader';
import { dashboardPage, loginPage } from '../constants/pagesRoutesContants';
import Role from '../enums/RoleEnum';

interface Props {
    children: ReactNode;
    roles: Role[];
}

const PrivateRoute = (props: Props) => {
    const { children, roles } = props;
    const { isAuth, user, role } = useAuth();

    if (!user) {
        return <Loader />;
    }

    if (!isAuth) {
        return <Navigate to={loginPage.path} />;
    }

    if (roles && role && !roles.includes(role)) {
        return <Navigate to={dashboardPage.path} />;
    }

    return <>{children}</>;
};

export default PrivateRoute;
