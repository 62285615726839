// import styles from "./style.module.scss";
import { Icon, Map } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useEffect, useState } from 'react';
import { Marker, Popup, useMap } from 'react-leaflet';
import human_location from '../../../assets/human-location.svg';
import { initialCoordonates } from '../../../constants/mapContants';
import AddressType from '../../../types/AddressType';

interface Props {
    userLocation: AddressType;
}

function LocationMarker(props: Props) {
    const map = useMap();
    const { userLocation } = props;
    const [myLocation, setMyLocation] =
        useState<AddressType>(initialCoordonates);

    const myIcon = new Icon({
        iconUrl: human_location,
        iconSize: [32, 32],
    });

    useEffect(() => {
        if (
            navigator.geolocation &&
            (!userLocation?.latitude || !userLocation?.longitude)
        ) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const myNewLocation = {
                        address: 'myLocation',
                        latitude,
                        longitude,
                        zoom: 14,
                        animated: false,
                    };
                    changeLocation(myNewLocation, map);
                    setMyLocation(myNewLocation);
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            if (
                userLocation.address &&
                userLocation.latitude &&
                userLocation.longitude
            ) {
                const myNewLocation = {
                    address: userLocation.address,
                    latitude: userLocation.latitude,
                    longitude: userLocation.longitude,
                    zoom: 14,
                    animated: false,
                };
                changeLocation(myNewLocation, map);
                setMyLocation(myNewLocation);
            }
        }
    }, [userLocation, map]);

    return !myLocation.latitude ||
        !myLocation.longitude ||
        myLocation.address === initialCoordonates.address ? null : (
        <Marker
            position={[myLocation.latitude, myLocation.longitude]}
            icon={myIcon}
        >
            <Popup>You are here</Popup>
        </Marker>
    );
}

function changeLocation(location: AddressType, map: Map) {
    if (location.latitude && location.longitude) {
        map.flyTo(
            [location.latitude, location.longitude],
            location.zoom || 14,
            {
                animate: location.animated === false ? location.animated : true,
            }
        );
    }
}

export default LocationMarker;
