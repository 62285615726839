import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import ListCases from '..';
import Loader from '../../../../components/Loader';
import {
    fetchClientCase,
    fetchClientCases,
    postClientCase,
} from '../../../../services/caseService';

function ClientsCases() {
    // ajouter une page de détails des étapes du dossier
    const { pathname } = useLocation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const caseId = searchParams.get('id') || '';
    const { isPending: isPendingClientsCases, data } = useQuery({
        queryKey: ['clientCases', pathname],
        queryFn: fetchClientCases,
    });

    if (isPendingClientsCases) {
        return <Loader />;
    }
    return (
        <ListCases
            data={data?.data}
            caseId={caseId}
            detailRequest={fetchClientCase}
            formRequest={postClientCase}
        />
    );
}

export default ClientsCases;
