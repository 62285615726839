import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from '../../../components/Loader';
import SideNav from '../../../components/SideNav';
import Toolbar from '../../../components/Toolbar';
import NavDataInterface from '../../../interfaces/NavDataInterface';
import { fetchNotes } from '../../../services/noteService';
import NoteType from '../../../types/NoteType';
import NoteDetails from './noteDetails';
import styles from './style.module.scss';

function Notes() {
    const { hash: noteId } = useLocation();
    const [typeAction, setTypeAction] = useState<'view_note' | null>(null);

    const { isPending: isPendingNotes, data: notes } = useQuery({
        queryKey: ['notes'],
        queryFn: async () => {
            try {
                const { data } = await fetchNotes();
                return data || [];
            } catch (error) {}
            return [];
        },
    });

    useEffect(() => {
        if (noteId) {
            setTypeAction('view_note');
        }
    }, [noteId]);

    const navData: NavDataInterface[] = notes?.map(
        (note: NoteType): NavDataInterface => {
            return {
                id: note.id || '',
                label: `${note.title}`,
                subLabel: `${note.clientName || ''} ${
                    note.clientCaseName ? '( ' + note.clientCaseName + ' )' : ''
                }`.trim(),
                date: note.lastEditDate,
                link: '?id=' + note.id,
                variant: 'tertiary',
                icon: 'fa-sticky-note',
                iconColor: 'yellow',
            };
        }
    );
    const handleClickNewConversation = () => {
        setTypeAction('view_note');
    };
    const [toolbar, setToolbar] = useState<NavDataInterface[]>([
        {
            id: 'newNote',
            label: 'Nouvelle Note',
            icon: 'fa-sticky-note',
            onClick: handleClickNewConversation,
        },
    ]);

    if (isPendingNotes) {
        <Loader />;
    }

    return (
        <div className={styles.container}>
            <SideNav
                selected=""
                navData={navData}
                extendable={typeAction !== null}
                hasSearchBar={true}
                searchBarLabel="Notes :"
            />
            <div className={styles.body}>
                <Toolbar toolbarData={toolbar} isSticky={typeAction !== null} />
                {typeAction && <NoteDetails setToolbar={setToolbar} />}
            </div>
        </div>
    );
}

export default Notes;
