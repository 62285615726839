import { ReactNode } from 'react';
import Footer from '../components/Layouts/Footer';
import Header from '../components/Layouts/Header';

interface Props {
    children: ReactNode;
}

const PublicRoute = (props: Props) => {
    const { children } = props;
    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    );
};

export default PublicRoute;
