import { ReactNode } from 'react';
import Card from '../../Card';
import LoaderDot from '../../LoaderDot';
import styles from './style.module.scss';

interface Props {
    children: ReactNode;
    isPending?: boolean;
    title?: string;
}

function WidgetCard(props: Props) {
    const { children, isPending = false, title } = props;
    return (
        <Card className={styles.widget}>
            <h2>{title}</h2>
            <div className={styles.containerFav}>
                {isPending ? <LoaderDot /> : children}
            </div>
        </Card>
    );
}

export default WidgetCard;
