import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import SideNav from '../../../components/SideNav';
import Toolbar from '../../../components/Toolbar';
import CreationCaseInterface from '../../../interfaces/CreationCaseInterface';
import NavDataInterface from '../../../interfaces/NavDataInterface';
import CaseType from '../../../types/CaseType';
import CaseDetails from './caseDetails';
import CaseTextEditor from './caseDetails/caseTextEditor';
import SearchForCase from './caseDetails/searchForCase';
import CaseForm from './caseForm';
import styles from './style.module.scss';

interface Props {
    data: CaseType[] | undefined;
    caseId: string;
    detailRequest: (caseId: string) => Promise<AxiosResponse<unknown, unknown>>;
    formRequest: (
        data: CreationCaseInterface
    ) => Promise<AxiosResponse<unknown, unknown>>;
}

function ListCases(props: Props) {
    const { data = [], caseId, detailRequest, formRequest } = props;
    // ajouter une page de détails des étapes du dossier

    const [cases, setCases] = useState<CaseType[]>([]);
    const [typeAction, setTypeAction] = useState<
        'new_file' | 'new_case' | 'view_case' | 'view_search' | null
    >(null);

    useEffect(() => {
        setCases(data);
        if (caseId) {
            setTypeAction('view_case');
        }
    }, [data, setCases, caseId]);

    const handleClickNewCase = () => {
        setTypeAction('new_case');
    };

    const defaultToolbar: NavDataInterface[] = [
        {
            id: 'toolbarBtn0',
            icon: 'fa-folder',
            label: 'Nouveau dossier',
            onClick: handleClickNewCase,
        },
    ];

    const navData: NavDataInterface[] = cases.map(
        (userCase): NavDataInterface => {
            return {
                id: userCase.id,
                label: userCase.caseName || userCase.caseRef,
                subLabel: userCase.user,
                date: new Date(),
                link: '?id=' + userCase.id,
                variant: 'tertiary',
                icon: 'fa-folder',
                iconColor: '#ffbc20',
            };
        }
    );

    const [toolbar, setToolbar] = useState<NavDataInterface[]>();

    const viewObject = {
        new_file: {
            view: (
                <CaseTextEditor
                    setToolbar={setToolbar}
                    setTypeAction={setTypeAction}
                />
            ),
        },
        new_case: {
            view: (
                <CaseForm
                    setToolbar={setToolbar}
                    setTypeAction={setTypeAction}
                    request={formRequest}
                />
            ),
        },
        view_case: {
            view: (
                <CaseDetails
                    setToolbar={setToolbar}
                    setTypeAction={setTypeAction}
                    request={detailRequest}
                    caseId={caseId}
                />
            ),
        },
        view_search: {
            view: (
                <SearchForCase
                    setToolbar={setToolbar}
                    setTypeAction={setTypeAction}
                />
            ),
        },
    };

    return (
        <div className={styles.container}>
            <SideNav
                selected={caseId || ''}
                navData={navData}
                extendable={caseId.length > 0}
                hasSearchBar={true}
                searchBarLabel="Dossiers :"
                reduceIcon={faFolder}
            />
            <div className={styles.body}>
                <Toolbar
                    toolbarData={caseId.length > 0 ? toolbar : defaultToolbar}
                    isSticky={caseId.length > 0}
                />
                {typeAction && viewObject[typeAction].view}
            </div>
        </div>
    );
}

export default ListCases;
