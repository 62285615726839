import {
    faChevronRight,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useEffect, useState } from 'react';
import { classNames } from '../../helpers/classNames';
import NavDataInterface from '../../interfaces/NavDataInterface';
import ButtonVariant from '../ButtonVariant';
import FaIcon from '../Icons/FaIcon';
import Input from '../Input';
import styles from './style.module.scss';

interface Props {
    navData: NavDataInterface[];
    children?: any;
    className?: any;
    extendable?: boolean;
    isSticky?: boolean;
    selected: string;
    hasSearchBar?: boolean;
    searchBarLabel?: string;
    handleSearchChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    reduceIcon?: IconDefinition;
}

function SideNav(props: Props) {
    const {
        navData,
        children = '',
        className,
        extendable = false,
        selected,
        isSticky,
        hasSearchBar = false,
        searchBarLabel = '',
        handleSearchChange,
        reduceIcon = faChevronRight,
    } = props;

    const [data, setData] = useState<NavDataInterface[]>([]);
    const [isBarVisible, setIsBarVisible] = useState(true);

    useEffect(() => {
        setData(navData);
        setIsBarVisible(!extendable);
    }, [navData, extendable]);

    const handleSearchCase = (event: ChangeEvent<HTMLInputElement>) => {
        const { value = '' } = event.target;
        const searchedCase = navData.filter((x: NavDataInterface) => {
            return (
                x.label
                    .toLocaleLowerCase()
                    .includes(value.toLocaleLowerCase()) ||
                x.subLabel
                    ?.toLocaleLowerCase()
                    .includes(value.toLocaleLowerCase())
            );
        });
        setData(searchedCase);
        if (handleSearchChange) {
            handleSearchChange(event);
        }
    };

    const handleMouseEnter = () => setIsBarVisible(true);
    const handleMouseLeave = () => {
        if (extendable) {
            setIsBarVisible(false);
        }
    };

    return (
        <>
            {!isBarVisible ? (
                <div
                    onMouseEnter={handleMouseEnter}
                    className={styles.extendableButton}
                >
                    <FontAwesomeIcon icon={reduceIcon} />
                </div>
            ) : (
                <div
                    className={classNames([styles.sideNav, className])}
                    onMouseLeave={handleMouseLeave}
                >
                    <div
                        className={classNames([isSticky ? styles.sticky : ''])}
                    >
                        <div className={styles.navHeader}>
                            {hasSearchBar && (
                                <div className={styles.navSearchBar}>
                                    <Input
                                        label={searchBarLabel}
                                        onChange={handleSearchCase}
                                    />
                                </div>
                            )}
                            {children}
                        </div>
                        <div className={styles.linksContainer}>
                            {data?.map((item) => (
                                <div
                                    className={classNames([
                                        styles.sideNavBtn,
                                        item.id.includes(selected) &&
                                        selected.length > 0
                                            ? styles.selected
                                            : '',
                                    ])}
                                    key={item.label}
                                >
                                    <ButtonVariant
                                        variant={item.variant}
                                        link={item.link}
                                        key={item.id || item.label}
                                        onClick={(e: MouseEvent) => {
                                            if (item.onClick) {
                                                item.onClick({
                                                    e,
                                                    value: item,
                                                });
                                            }
                                        }}
                                    >
                                        {item.icon && (
                                            <FaIcon
                                                styleIcon={{
                                                    color: item.iconColor
                                                        ? item.iconColor
                                                        : 'inherit',
                                                }}
                                            >
                                                {item.icon}
                                            </FaIcon>
                                        )}
                                        <span className={styles.btn}>
                                            <label className={styles.label}>
                                                {item.label}
                                            </label>
                                            <span className={styles.subText}>
                                                {!!item.subLabel && (
                                                    <label
                                                        className={
                                                            styles.subLabel
                                                        }
                                                    >
                                                        {item.subLabel}
                                                    </label>
                                                )}
                                                {!!item.date && (
                                                    <label
                                                        className={styles.date}
                                                    >
                                                        {item.date.toLocaleString()}
                                                    </label>
                                                )}
                                            </span>
                                        </span>
                                    </ButtonVariant>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default SideNav;
