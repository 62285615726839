import Timer from '../../Timer';
import WidgetCard from '../WidgetCard';
function TimerWidget() {
    const startDate = new Date('2014/04/17 06:00:00');
    const endDate = new Date('2014/04/17 07:00:00');
    return (
        <WidgetCard>
            <Timer startDate={startDate} endDate={endDate} />
        </WidgetCard>
    );
}

export default TimerWidget;
