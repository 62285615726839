import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';
import userPhoto from '../../assets/user.jpg';
import { useAuth } from '../../AuthProvider';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import SideNav from '../../components/SideNav';
import {
    availabilitiesPage,
    billingPage,
    clientCasesPage,
    contactPage,
    dashboardPage,
    formsPage,
    lawfirmSettingsPage,
    loginPage,
    meetingsPage,
    messagesPage,
    myCasesPage,
    notePage,
    servicesPage,
    settingsPage,
    statisticsPage,
} from '../../constants/pagesRoutesContants';
import { storageItems } from '../../constants/storageItemsContants';
import Role from '../../enums/RoleEnum';
import NavDataInterface from '../../interfaces/NavDataInterface';
import styles from './style.module.scss';

function Profile() {
    const { isAuth, user, userLogout } = useAuth();
    const { hash, pathname: location } = useLocation();

    if (!isAuth) {
        if (!loginPage.path.includes(location)) {
            sessionStorage.setItem(storageItems.landingPage, location + hash);
        }
        return <Navigate to={loginPage.path} replace />;
    }

    sessionStorage.removeItem(storageItems.landingPage);

    const role: Role.Lawyer | Role.User =
        user?.role === Role.Lawyer ? Role.Lawyer : Role.User;

    const navDataUser: NavDataInterface[] = [
        {
            id: dashboardPage.path,
            number: 1,
            variant: 'tertiary',
            link: dashboardPage.path,
            label: dashboardPage.name,
            icon: 'fa-dashboard',
            //iconColor: '#7ebed0',
        },
        {
            id: meetingsPage.path,
            number: 7,
            variant: 'tertiary',
            link: meetingsPage.path,
            label: meetingsPage.name,
            icon: 'fa-calendar-days',
            //iconColor: '#ff4f4f',
        },
        {
            id: myCasesPage.path,
            number: 3,
            variant: 'tertiary',
            link: myCasesPage.path,
            label: myCasesPage.name,
            icon: 'fa-folder-open',
            //iconColor: '#ffbc20',
        },
        {
            id: messagesPage.path,
            number: 6,
            variant: 'tertiary',
            link: messagesPage.path,
            label: messagesPage.name,
            icon: 'fa-envelope',
            //iconColor: '#133c65',
        },
        {
            id: contactPage.path,
            number: 6,
            variant: 'tertiary',
            link: contactPage.path,
            label: contactPage.name[role],
            icon: 'fa-address-card',
            //iconColor: '#97ff8d',
        },

        {
            id: settingsPage.path,
            number: 11,
            variant: 'tertiary',
            link: settingsPage.path,
            label: settingsPage.name,
            icon: 'fa-gear',
            //iconColor: 'grey',
        },
    ];

    const navDataLawyer: NavDataInterface[] = [
        ...navDataUser,
        {
            id: formsPage.path,
            number: 4,
            variant: 'tertiary',
            link: formsPage.path,
            label: formsPage.name,
            icon: 'fa-file-lines',
            //iconColor: 'grey',
        },
        {
            id: clientCasesPage.path,
            number: 2,
            variant: 'tertiary',
            link: clientCasesPage.path,
            label: clientCasesPage.name,
            icon: 'fa-folder-open',
            //iconColor: '#ffbc20',
        },
        {
            id: notePage.path,
            number: 8,
            variant: 'tertiary',
            link: notePage.path,
            label: notePage.name,
            icon: 'fa-sticky-note',
            //iconColor: 'yellow',
        },
        {
            id: availabilitiesPage.path,
            number: 9,
            variant: 'tertiary',
            link: availabilitiesPage.path,
            label: availabilitiesPage.name,
            icon: 'fa-business-time',
            //iconColor: '#582900',
        },
        {
            id: servicesPage.path,
            number: 10,
            variant: 'tertiary',
            link: servicesPage.path,
            label: servicesPage.name,
            icon: 'fa-coins',
            //iconColor: '#ffd700',
        },
        {
            id: statisticsPage.path,
            number: 11,
            variant: 'tertiary',
            link: statisticsPage.path,
            label: statisticsPage.name,
            icon: 'fa-chart-pie',
            //iconColor: 'black',
        },
        {
            id: billingPage.path,
            number: 12,
            variant: 'tertiary',
            link: billingPage.path,
            label: billingPage.name,
            icon: 'fa-file-invoice-dollar',
            //iconColor: 'white',
        },
        {
            id: lawfirmSettingsPage.path,
            number: 13,
            variant: 'tertiary',
            link: lawfirmSettingsPage.path,
            label: lawfirmSettingsPage.name,
            icon: 'fa-landmark',
            //iconColor: 'gray',
        },
    ];

    const navData: NavDataInterface[] =
        user?.role === Role.Lawyer
            ? navDataLawyer.sort((a, b) => {
                  if (a.number && b.number) {
                      return a.number - b.number;
                  }
                  return 0;
              })
            : navDataUser;

    if (!user) {
        <Loader />;
    }

    return (
        <div className={styles.container}>
            <SideNav selected={location} navData={navData} isSticky={true}>
                <h1 className={styles.title}>
                    <Link to="/">Liber'all</Link>
                </h1>
                <div className={styles.userContainer}>
                    <div className={styles.photoContainer}>
                        <img
                            src={user?.photo || userPhoto}
                            alt={(
                                user?.firstName +
                                '_' +
                                user?.lastName
                            ).replace(' ', '_')}
                            className={styles.photoCard}
                        />
                    </div>
                    <div className={styles.identity}>
                        <div className={styles.nameContainer}>
                            {user?.firstName}
                            {user?.lastName?.toLocaleUpperCase()}
                        </div>
                        <div className={styles.btn}>
                            <Button variant="primaryLight" onClick={userLogout}>
                                <FontAwesomeIcon icon={faPowerOff} />
                                <span>Se deconnecter</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </SideNav>
            <div className={styles.pagesContainer}>
                <Outlet context={user} />
            </div>
        </div>
    );
}

export default Profile;
